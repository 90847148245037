import { Route } from "react-router-dom";
import About from "./views/About/About";
import Advertise from "./views/Advertise/Advertise";
import Contact from "./views/Contact/Contact";
import { createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import PrivacyPolicy from "./views/PrivacyPolicy/PrivacyPolicy";

const router = createBrowserRouter([
  {
    path: "/",
    element: <About />,
  },
  {
    path: "/advertise",
    element: <Advertise />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
]);
export default router
