import React from 'react'
import Boost from '../../components/Boost/Boost'
import Footer from '../../components/Footer/Footer'
import GoldfinchBar from '../../components/GoldFinchBar/GoldfinchBar'
import GrowSec from '../../components/Grow/GrowSec'
import NavBar from '../../components/Navbar/NavBar'
import SignUpSec from '../../components/Signup/SignUpSec'
import VideoSec from '../../components/Video/VideoSec'
import VIDEO_FINAL from '../../assets/Video/Final.mp4'


const Advertise = () => {
  return (
    <div className='container'>
      <NavBar  />
      <VideoSec src={VIDEO_FINAL} />
      <Boost />
      <GoldfinchBar />
      <GrowSec />
      <SignUpSec />
      <Footer />
    </div>
  )
}

export default Advertise
