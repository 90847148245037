import React from "react";
import './PrivacyContent.css'

const Content = () => {
  let data = [
    {
      head: "Information We Collect",
      subheadA: "Personal Information:",
      subheadB: " Location Information:",
      contentA:
        "We may collect personal information such as your full name, email address, phone number, gender, date of birth, and password when you create an account with the App.",
      contentB:
        "We use your device's GPS or location services to determine your location to provide you with the best deals and discounts near you. By using the App, you agree to allow us to collect and use your location information.",
    },
    {
      head: "How We Use Your Information",
      subheadA: "Personal Information:",
      subheadB: " Location Information:",
      contentA:
        "We use your personal information to create and manage your account, provide you with the App's services, and communicate with you.",
      contentB:
        " We use your location information to provide you with the best deals and discounts near you and to personalize your experience.",
    },
    {
      head: "Sharing of Information",
      subheadA: "Third-Party Service Providers:",
      subheadB: " Legal Compliance:",
      contentA:
        "We may share your personal information and location information with third-party service providers who assist us in providing the App's services. These service providers are bound by strict confidentiality agreements and are only allowed to use your information in accordance with our instructions.",
      contentB:
        "We may disclose your personal information and location information if required by law or if we believe that such disclosure is necessary to comply with legal process, to protect the rights and safety of Goldfinch or others, or to enforce our terms and conditions.",
    },
  ];
  return data.map((item) => {
    return (
      <div>
        <div style={{ marginBottom: "1rem" }}>
          <p
            style={{
              fontFamily: "Helvetica",
              fontSize: "18px",
              fontWeight: "bolder",
              color: "#434343",
            }}
          >
            {item.head}
          </p>
        </div>
        <div style={{ marginBottom: "0.7rem" }}>
          <p style={{ fontFamily: "Helvetica", color: "#434343", lineHeight:1.5}}>
            a.{" "}
            <span
              style={{
                fontFamily: "Helvetica",
                fontWeight: "bold",
                color: "#434343",
              }}
            >
              {item.subheadA}
            </span>{" "}
            <span
              style={{
                fontFamily: "Helvetica",
                fontSize: "16px",
                color: "#434343",
              }}
            >
              {item.contentA}
            </span>
          </p>
        </div>
        <div style={{ marginBottom: "0.7rem" }}>
          <p style={{ fontFamily: "Helvetica", color: "#434343", lineHeight:1.5 }}>
            b.{" "}
            <span
              style={{
                fontFamily: "Helvetica",
                fontWeight: "bold",
                color: "#434343",
              }}
            >
              {item.subheadB}
            </span>{" "}
            <span
              style={{
                fontFamily: "Helvetica",
                fontSize: "16px",
                color: "#434343",
              }}
            >
              {item.contentB}
            </span>
          </p>
        </div>
      </div>
    );
  });
};
const PrivacyContent = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        rowGap: 15,
        maxWidth: "1320px",
        margin: "0 auto",
        borderTop: "1px solid #DE2746",
        paddingTop: "1rem",
        paddingBottom: "2rem",
        paddingRight:'1.5rem',
        paddingLeft:'1.5rem'
      }}
    >
      <div style={{ marginBottom: "2rem" }}>
        <h1
          style={{
            fontFamily: "Helvetica",
            textAlign: "center",
            color: "#434343",
          }}
          className="privacyHead"
        >
          Privacy Policy
        </h1>
      </div>
      <div>
        <p
          style={{
            fontFamily: "Helvetica",
            fontSize: "16px",
            color: "#434343",
            lineHeight:1.5
          }}
        >
          <span
            style={{
              fontFamily: "Helvetica",
              fontWeight: "bold",
              fontSize: "16px",
              color: "#434343",
            }}
          >
            Goldfinch.App Inc.
          </span>{" "}
          ("Goldfinch," "we," "us," or "our") values the privacy of its users
          ("you" or "your"). This Privacy Policy explains how we collect, use,
          and share information through our Goldfinch mobile application (the
          "App"). By using the App, you agree to the terms of this Privacy
          Policy.
        </p>
      </div>
      <Content />
      <div>
        <p
          style={{
            fontFamily: "Helvetica",
            fontSize: "18px",
            fontWeight: "bolder",
            color: "#434343",
          }}
        >
          Data Security
        </p>
        <p
          style={{
            fontFamily: "Helvetica",
            color: "#434343",
            fontSize: "16px",
            marginTop: "1rem",
            lineHeight:1.5
          }}
        >
          We take reasonable measures to protect your personal information and
          location information from unauthorized access, use, or disclosure.
          However, no method of electronic storage or transmission is 100%
          secure, and we cannot guarantee the absolute security of your
          information.
        </p>
      </div>
      <div>
        <p
          style={{
            fontFamily: "Helvetica",
            fontSize: "18px",
            fontWeight: "bolder",
            color: "#434343",
          }}
        >
          Deletion of Information
        </p>
        <p
          style={{
            fontFamily: "Helvetica",
            color: "#434343",
            fontSize: "16px",
            marginTop: "1rem",
            lineHeight:1.5
          }}
        >
          If you delete your account, we will remove your personal information
          and location information from our database. However, some information
          may remain in our archives for the purpose of compliance with legal
          obligations or for our internal record keeping.
        </p>
      </div>
      <div>
        <p
          style={{
            fontFamily: "Helvetica",
            fontSize: "18px",
            fontWeight: "bolder",
            color: "#434343",
          }}
        >
          Changes to This Privacy Policy
        </p>
        <p
          style={{
            fontFamily: "Helvetica",
            color: "#434343",
            fontSize: "16px",
            marginTop: "1rem",
            lineHeight:1.5
          }}
        >
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or the law. Your continued use of the App constitutes
          your acceptance of any changes to this Privacy Policy.
        </p>
      </div>
      <div>
        <p
          style={{
            fontFamily: "Helvetica",
            fontSize: "18px",
            fontWeight: "bolder",
            color: "#434343",
          }}
        >
          Contact Us
        </p>
        <p
          style={{
            fontFamily: "Helvetica",
            color: "#434343",
            fontSize: "16px",
            marginTop: "1rem",
          }}
        >
          If you have any questions or concerns about this Privacy Policy or our
          data collection and use practices, please contact us at
          [admin@thegoldfinch.app].
        </p>
        <p
          style={{
            fontFamily: "Helvetica",
            color: "#434343",
            fontSize: "16px",
            marginTop: "1rem",
          }}
        >
          This Privacy Policy is governed by the laws of Canada.
        </p>
        <p
          style={{
            fontFamily: "Helvetica",
            color: "#434343",
            fontSize: "16px",
            marginTop: "1rem",
          }}
        >
          Last updated: 25, February 2023.
        </p>
      </div>
    </div>
  );
};

export default PrivacyContent;
