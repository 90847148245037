import React from "react";
import "./MaximizeSec.css";
import IMG_MAP from "../../assets/images/map.png";
import IMG_UND from "../../assets/images/Path23747.png";

const MaximizeSec = () => {
  const onClickFunc = () => {
    window.location.href = "https://partners.thegoldfinch.app";
  };

  return (
    <div className="maxContainer">
      <div className="maxWrapper">
        <div className="maxContContainer">
          <p className="maxHead">Maximize Your Reach with GoldFinch</p>
          <img src={IMG_UND} alt="" className="maxHeadUnderImg" />
          <p className="maxText" style={{ marginBottom: "20px" }}>
            Attract more customers and boost your sales with GoldFinch. Our app
            is a powerful platform that connects you with local shoppers,
            offering instant and exclusive discounts on your products and
            services. With our location-based technology, your business will
            reach the right customers at the right time.
          </p>
          <p className="maxText" style={{ marginBottom: "20px" }}>
            {" "}
            Join the savings revolution and advertise with us today. Take
            advantage of our extensive network of shoppers, and enjoy increased
            brand exposure, enhanced customer loyalty, and increased sales.{" "}
          </p>
          <p className="maxText">
            Don't wait any longer, sign up now and start seeing the benefits of
            advertising with GoldFinch.
          </p>
          <div className="btnContainer">
            <button
              className="maximizeBtn"
              onClick={() => {
                onClickFunc();
              }}
            >
              Get Started
            </button>
          </div>
        </div>
        <div className="maxImgContainer">
          <img src={IMG_MAP} alt="" className="maxImg" />
        </div>
      </div>
    </div>
  );
};

export default MaximizeSec;
