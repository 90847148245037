import React from "react";
import "./GoldfinchBar.css";

const GoldfinchBar = () => {
  const onClickFunc = () => {
    window.location.href = "https://partners.thegoldfinch.app";
  };
  return (
    <div className="barWrapper">
      <div className="textWrapper">
        <p className="barText">
          Sign Up with GoldFinch today and see the results for yourself.
        </p>
      </div>
      <div className="btnWrapper">
        <button
          className="barBtn"
          onClick={() => {
            onClickFunc();
          }}
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default GoldfinchBar;
