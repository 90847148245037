import React from 'react'
import ContactSec from '../../components/Contact/ContactSec'
import Footer from '../../components/Footer/Footer'
import NavBar from '../../components/Navbar/NavBar'
import DownloadSec from '../../components/Download/DownloadSec'


const Contact = () => {
  return (
    <div className='container'>
      <NavBar  />
      <ContactSec />
      <DownloadSec />
      <Footer />
    </div>
  )
}

export default Contact
