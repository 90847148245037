import React from 'react'
import Footer from '../../components/Footer/Footer'
import NavBar from '../../components/Navbar/NavBar'
import PrivacyContent from '../../components/PrivacyContent/PrivacyContent'

const PrivacyPolicy = () => {
  return (
    <div className='container'>
      <NavBar  />
      <PrivacyContent />
      <Footer />
    </div>
  )
}

export default PrivacyPolicy
