import React from 'react'
import Appwork from '../../components/Appwork/Appwork'
import DownloadSec from '../../components/Download/DownloadSec'
import Footer from '../../components/Footer/Footer'
import MaximizeSec from '../../components/Maximize/MaximizeSec'
import MobileMockup from '../../components/MobileMockup/MobileMockup'
import NavBar from '../../components/Navbar/NavBar'
import SavingSec from '../../components/Saving/SavingSec'
import SurpriseSec from '../../components/Surprise/SurpriseSec'
import VideoSec from '../../components/Video/VideoSec'
import './About.css'
import VIDEO_GOLDFINCH from '../../assets/Video/GoldFinch.mp4'


const About = () => {
  return (
    <div className='container'>
      <NavBar  />
      <VideoSec src={VIDEO_GOLDFINCH}  />
      <Appwork />
      <SavingSec />
      <SurpriseSec />
      <MobileMockup />
      <MaximizeSec />
      <DownloadSec />
      <Footer />
    </div>
  )
}

export default About
