import React, { useState } from "react";
import "./ContactSec.css";
import IMG_UND from "../../assets/svg/Path42209.svg";

const ContactSec = () => {
  const [name, setName] = useState("");
  const [brandName, setBrandName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");

  const onSubmitFunc = () => {
    console.log(
      "name",
      name,
      "brandName",
      brandName,
      "email",
      email,
      "mobile",
      mobile,
      "message",
      message
    );

    const data = {
      name: name,
      brandName: brandName,
      email: email,
      mobile: mobile,
      message: message,
    };

    fetch("http://localhost:5000/submit", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setName("");
    setBrandName("");
    setEmail("");
    setMobile("");
    setMessage("");
  };

  return (
    <div className="contactContainer">
      <div className="contactWrapper">
        <div className="column">
          <div className="row">
            <p className="contactHead">Contact Us</p>
            <img src={IMG_UND} alt="" className="contactUnderline" />
            <p className="contactText">
              We at GoldFinch are always looking for new and innovative ways to
              bring value to our users. If you're interested in advertising with
              us, please fill out the form below and one of our team members
              will be in touch shortly.
            </p>
            <p className="contactText">
              We believe that partnerships between businesses and consumers are
              essential in creating a thriving and vibrant community. By
              advertising with us, you'll be able to reach a highly engaged
              audience and promote your products and services in a meaningful
              way.
            </p>
            <p className="contactText">
              At GoldFinch, we're committed to ensuring that all of our
              advertising partners receive the support they need to succeed.
              Whether you're a small business looking to reach a local audience
              or a large corporation looking to expand your brand, our team is
              here to help.
            </p>
            <p className="contactText">
              So, if you're interested in advertising with us, please don't
              hesitate to get in touch. We'd love to hear from you!{" "}
            </p>
          </div>
          <div className="row">
            <div className="contactFormContainer">
              <div>
                <input
                  className="inputField"
                  value={name}
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <input
                  className="inputField"
                  value={brandName}
                  placeholder="Brand Name"
                  onChange={(e) => setBrandName(e.target.value)}
                />
              </div>
              <div>
                <input
                  className="inputField"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <input
                  className="inputField"
                  value={mobile}
                  placeholder="Mobile"
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
              <div>
                <textarea
                  name=""
                  id=""
                  rows="13"
                  placeholder="Message"
                  className="textMesssage"
                  style={{
                    borderRadius: 20,
                    color: "#363636",
                    outline: "none",
                    borderColor: "#363636",
                    height: "auto",
                    
                  }}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <div>
                <button
                  className="btn"
                  onClick={() => {
                    onSubmitFunc();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSec;
