import React from 'react'
import './SurpriseSec.css'
import IMG_MOB_SUR from '../../assets/images/Group11076.png'
import IMG_GOOGLE_STORE from '../../assets/images/MaskGroup328.png'
import IMG_IOS_STORE from '../../assets/images/MaskGroup327.png'

const SurpriseSec = () => {
    return (
        <div className='surpContainer'>
            <div className='wrapper'>
                <div className='mainWrapper'>
                    <p className='surpriseHead'>Unlock the Power of Surprises with GoldFinch.</p>
                    <p className='surpriseText'>Turn on your location and notifications for the GoldFinch App and be the first to know about exclusive discounts near you. From 70% off bills at your favorite brands to buy 1 get 1 free offers at local restaurants and flash sales in shopping centers, you won't want to miss the chance to save big.</p>
                    <p className='surpriseSubHead'>Download the App and Join the savings revolution with GoldFinch today.</p>
                    <div className='surImgContainer'>
                        <img src={IMG_IOS_STORE} alt=""  className='surDownImg'/>
                        <img src={IMG_GOOGLE_STORE} alt="" className='surDownImg' />
                    </div>
                    <div className='surpriseMob'>
                        <img src={IMG_MOB_SUR} alt="" className='surpImg' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SurpriseSec
