import React from 'react'
import './MobileMockup.css'
import IMG_MOB_PRO from '../../assets/images/phone_14_pro.png'

const MobileMockup = () => {
  return (
    <div className='mockWrapper'>
      <div className='proImgContainer'>
        <img src={IMG_MOB_PRO} alt="" className='mockImg' />
      </div>
    </div>
  )
}

export default MobileMockup
