import React, { Component} from 'react';
import { Button } from '@mui/material';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import IMG_SEA_GOL from '../../assets/images/Asset6.png'
import IMG_DEAL_GOL from '../../assets/images/Asset.png'
import IMG_REM_GOL from '../../assets/images/Asset9.png'
import IMG_CUS_GOL from '../../assets/images/Asset10.png'

class DemoCarousel extends Component {
    render() {
        return (
            <Carousel showStatus={false} showIndicators={true} showThumbs={false} infiniteLoop={true} autoPlay={true}>
                <div>
                    <img src={IMG_SEA_GOL} />
                    <p className="legend" style={{fontFamily: 'SF UI Display',}}>Stay up-to-date with the latest deals. The GoldFinch App's real-time geo-search feature locates the best discounts near you.</p>
                </div>
                <div>
                    <img src={IMG_CUS_GOL} />
                    <p className="legend" style={{fontFamily: 'SF UI Display',}}>Never miss out on a deal again. The "Save Now" button allows you to securely save exclusive discounts on your account, until the offer expires.</p>
                </div>
                <div>
                    <img src={IMG_REM_GOL} />
                    <p className="legend" style={{fontFamily: 'SF UI Display',}}>Stay on top of expiration dates. Set reminders for upcoming deals at your convenience.</p>
                </div>
                <div>
                    <img src={IMG_DEAL_GOL} />
                    <p className="legend" style={{fontFamily: 'SF UI Display',}}>Customize your experience. The app's categories feature lets you choose the discounts that fit your interests, for an effortless savings experience.</p>
                </div>
            </Carousel>
        );
    }
};
export default DemoCarousel