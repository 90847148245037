import React from 'react'
import './Appwork.css'
// import IMG_UND from '../assets/svg/Path42209.svg'
// import IMG_DIS from '../assets/svg/discount-3747.svg'
// import IMG_PIN from '../assets/svg/pin-location-4355.svg'
// import IMG_NOTI from '../assets/svg/notification-bell-13094.svg'
import IMG_UND from '../../assets/svg/Path42209.svg'
import IMG_DIS from '../../assets/svg/discount-3747.svg'
import IMG_PIN from '../../assets/svg/pin-location-4355.svg'
import IMG_NOTI from '../../assets/svg/notification-bell-13094.svg'

const Appwork = () => {
  return (
    <div className='mainWrapper'>
      <div className='contentContainer'>
        <p className='contentHead'>How Does The GoldFinch App Work?</p>
        <img src={IMG_UND} alt="" className='contentUnderline' />
      </div>
      <div className='workContContainer'>
        <div className='workContSubContainer'>
          <div className='workRow'>
            <img src={IMG_DIS} alt="" style={{ marginBottom: '10px',height:60 }} className="appWorkImg" />
            <p className='workContText'>Say goodbye to endless searching and waiting for the best discounts. The GoldFinch App uses your live location to bring you instant, exclusive offers in your area.</p>
          </div>
          <div className='workRow'>
            <img src={IMG_PIN} alt="" style={{ marginBottom: '10px',height:60 }} className="appWorkImg" />
            <p className='workContText'>Simply turn on your location and receive all exclusive offers directly to your device, without the hassle of sifting through multiple apps or waiting for email coupons.</p>
          </div>
          <div className='workRow'>
            <img src={IMG_NOTI} alt="" style={{ marginBottom: '10px',height:60 }} className="appWorkImg" />
            <p className='workContText'>Simplify your savings with the GoldFinch App. Location-based notifications bring all discounts to one convenient place, saving you time and effort.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Appwork
