import React, { useState } from 'react'
import IMG_LOGO from '../../assets/images/Asset2.png'
import { Link,NavLink } from 'react-router-dom'
import './NavBar.css'
// import IMG_HAM from '../../assets/images/menu.png'
// import * as React from 'react';
import MenuListComposition from '../Menu/Menu'

const NavBar = () => {
  
  return (
    <div className='mainContainer'>
      <div className='logo_container'>
        <Link to='/'><img src={IMG_LOGO} alt="" className='logoImg' /></Link>
      </div>
      <div className='navItemContainer'>
        <NavLink to='/' activeClassName="active" className='link'><p className='navItem'>About GoldFinch</p></NavLink>
        <NavLink to='/advertise' activeClassName="active" className='link'><p className='navItem'>Advertise With Us</p></NavLink>
        <NavLink to='/contact' activeClassName="active" className='link'><p className='navItem'>Contact Us</p></NavLink>
        {/* <Link to='/' className='link' onClick={()=>{activeMenuFunc}} style={{textDecoration:}}><p className='navItem'>About GoldFinch</p></Link>
        <Link to='/advertise' className='link' onClick={()=>{activeMenuFunc}} style={{textDecoration:}}><p className='navItem'>Advertise With Us</p></Link>
        <Link to='/contact' className='link' onClick={()=>{activeMenuFunc}} style={{textDecoration:}}><p className='navItem'>Contact Us</p></Link> */}
      </div>
      <div className='hamCont'>
        <MenuListComposition />
      </div>
    </div>
  )
}
export default NavBar

