import React from 'react'
import './GrowSec.css'
import IMG_UND_BOOST from '../../assets/images/bigund.png'
import IMG_FRANCHISE from '../../assets/images/franchises.png'
import IMG_PROMO from '../../assets/images/Promotions.png'
import IMG_AlARM from '../../assets/images/Alarm.png'
import IMG_ANALYSIS from '../../assets/images/Analysis.png'

const GrowSec = () => {
    let data = [
        {
            head:'Add Multiple Franchises',
            text:'We offer you the ability to add multiple franchises under one main account, allowing you to launch deals and promotions across all franchises with just a few clicks. When creating a franchise, you can define the location and store hours.',
            url:IMG_FRANCHISE
        },
        {
            head:'Create Deals and Promotions on Your Own Terms',
            text:'With GoldFinch, you have the power to create deals and promotions on your own terms. You can customize the title, description, duration, upload images, and promote all social links.',
            url:IMG_PROMO
        },
        {
            head:'Send Push Notifications',
            text:'Stay connected with your customers by sending push notifications to those who are near your shop. With our system, you can write a notification on your own terms when creating promotions or deals.',
            url:IMG_AlARM
        },
        {
            head:'Daily Analytics Report',
            text:'Gain insight into your GoldFinch statistics and performance with our advanced analytics. Our daily report shows statistics such as saved Goldfinches, user views, directions requested, phone number clicks, and more.',
            url:IMG_ANALYSIS
        },
    ]
  return (
    <div className='GrowContainer'>
      <div className='GrowHeadContainer'>
        <p className='GrowHead'>What We Offer and How We Can Help You Grow Your Business?</p>
        <img src={IMG_UND_BOOST} alt="" className='GrowUnder' />
      </div>
      {data.map((item,index)=>{
        return(
            <div className='GrowWrapper' style={{flexDirection: index % 2 === 0 ? 'row':'row-reverse'}}>
                <div className='GrowLeft' style={{justifyContent:index%2 === 0? 'flex-start':'flex-end'}}>
                    <img src={item.url} alt="" className='GrowImg' style={{width:index===2? '65%':'80%'}} />
                </div>
                <div className='GrowRight'>
                    <p className='GrowSubHead'>{item.head}</p>
                    <p className='GrowText'>{item.text}</p>
                </div>
            </div>
        )
      })  
      }
    </div>
  )
}

export default GrowSec
