import React from 'react'
import './Footer.css'
import IMG_FACEBOOK from '../../assets/images/facebook.png'
import IMG_INSTAGRAM from '../../assets/images/instagram.png'
import IMG_TWITTER from '../../assets/images/twitter.png'
import { Link, NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='footerContainer'>
      <div className='footerWrapper'>
        <div>
            <p className='footerText'>Copyright 2023 Goldfinch.App Inc. All rights reserved. <span><Link to='/privacy-policy' style={{color:'#E2E1E1'
            , textDecoration:'none', borderBottom:'1px solid #E2E1E1',fontFamily: "Merula"}}>Privacy Policy</Link></span></p>
        </div>
        <div className='socialIconContainer'>
            <img src={IMG_FACEBOOK} alt="" className='icon' />
            <img src={IMG_INSTAGRAM} alt="" className='icon' />
            <img src={IMG_TWITTER} alt="" className='icon' />
        </div>
      </div>
    </div>
  )
}
export default Footer
