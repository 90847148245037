import React from 'react'
import './DownloadSec.css'
import IMG_01 from '../../assets/images/01.png'
import IMG_GOOGLE_STORE from '../../assets/images/MaskGroup328.png'
import IMG_IOS_STORE from '../../assets/images/MaskGroup327.png'

const DownloadSec = () => {
    return (
        <div className='downContainer'>
            <div className='downWrapper'>
                <div className='downContContainer'>
                    <p className='downHead'>Get the best deals with just a tap! <br />Download the free GoldFinch app today!</p>
                    <div className='imgCont'>
                        <img src={IMG_IOS_STORE} alt="" className='img' />
                        <img src={IMG_GOOGLE_STORE} alt="" className='img' />
                    </div>  
                </div>
                <div className='downImgContainer'>
                    <img src={IMG_01} alt="" className='downImg' />
                </div>
            </div>
        </div>
    )
}

export default DownloadSec
