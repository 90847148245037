import React from 'react'
import './Boost.css'
import IMG_UND_BOOST from '../../assets/images/bigund.png'
import IMG_SALE from '../../assets/images/sale@2x.png'

const Boost = () => {
  return (
    <div className='boostContainer'>
      <div className='boostHeadContainer'>
        <p className='boostHead'>Boost your business revenue with GoldFinch technology</p>
        <img src={IMG_UND_BOOST} alt="" className='boostUnder' />
      </div>
      <div className='boostRow'>
        <div className='boostColumn left'>
          <p className='boostSubHead'>Are you tired of slow days and sluggish sales?</p>
          <p className='boostText'>With GoldFinch technology, you can instantly boost footfall during quiet times and attract new customers to your business. Our location-based app alerts customers to exclusive discounts and promotions near your shop, bringing them right to your doorstep. Say goodbye to empty stores and hello to a steady stream of customers with GoldFinch.</p>
        </div>
        <div className='boostColumn right'>
          <img src={IMG_SALE} alt="" className='boostImage' />
        </div>
      </div>
    </div>
  )
}

export default Boost
