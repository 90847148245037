import React from "react";
import "./SignUpSec.css";

const SignUpSec = () => {
  const onClickFunc = () => {
    window.location.href = "https://partners.thegoldfinch.app";
  };

  return (
    <div className="SignUpWrapper">
      <div className="SignUpContainer">
        <p className="SignUpHead">
          Sign Up with the <br /> GoldFinch To Grow Your <br /> Business Today!
        </p>
        <div className="btnWrapper SignUpBtnWrapper">
          <button
            className="SignUpBtn"
            onClick={() => {
              onClickFunc();
            }}
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUpSec;
