import React from "react";
import "./VideoSec.css";
// import VIDEO_GOLDFINCH from '../../assets/Video/GoldFinch.mp4'
import ReactPlayer from "react-player";

const VideoSec = ({ src }) => {
  return (
    <div className="videoContainer">
      <ReactPlayer
        url={src}
        playing={true}
        autoPlay={true}
        loop={false}
        controls={true}
        height="100vh"
        width="100vw"
        volume="1"
        muted={false}
        className="video"
        config={{
          file: {
            attributes: {
              autoPlay: true,
              muted: true,
            },
          },
        }}
      />
    </div>
  );
};

export default VideoSec;
