import React, { useState } from 'react'
import './SavingSec.css'
import { Button } from '@mui/material'
import IMG_SEA_GOL from '../../assets/images/Asset6.png'
import IMG_DEAL_GOL from '../../assets/images/Asset.png'
import IMG_REM_GOL from '../../assets/images/Asset9.png'
import IMG_CUS_GOL from '../../assets/images/Asset10.png'
import IMG_UND from '../../assets/images/Path42210@2x.png'
import DemoCarousel from '../Carousel/Carousel'

const SavingSec = () => {

  let data = [
    {
      text: "Stay up-to-date with the latest deals. The GoldFinch App's real-time geo-search feature locates the best discounts near you.",
      image: IMG_SEA_GOL,
      show: false,
    },
    {
      text: `Never miss out on a deal again. The "Save Now" button allows you to securely save exclusive discounts on your account, until the offer expires.`,
      image: IMG_CUS_GOL,
      show: false,
    },
    {
      text: "Stay on top of expiration dates. Set reminders for upcoming deals at your convenience.",
      image: IMG_REM_GOL,
      show: false,
    },
    {
      text: "Customize your experience. The app's categories feature lets you choose the discounts that fit your interests, for an effortless savings experience.",
      image: IMG_DEAL_GOL,
      show: false,
    },
  ]

  const [img, setImg] = useState(0)
  // const [border,setBorder] = useState(0)

  const changeImage = (currentIndex) => {
    setImg(currentIndex)
  }

  return (
    <div className='savingContainer'>
      <div className='savingRow'>
        <div className='column1'>
          <img src={data[img].image} alt="" className='savingImg' />
        </div>
        <div className='column2'>
          <div>
            <p className='savingHead'>Maximize Your Saving with the GoldFinch App</p>
            <img src={IMG_UND} alt="" className='savingUndImg' />
          </div>
          <div className='buttonContainer'>
            {data.map((item, index) => {
              return (
                <Button variant="text" onClick={() => { changeImage(index) }} className="savingBtn" sx={{ color: img === index ? '#242424' : '#E4E4E4', textTransform: 'none', borderRadius: 0, borderLeft: img === index ? 3 : 0, borderColor: '#388695', fontSize: { sm: '14px', md: '16px', },marginBottom:'-5px',lineHeight:'1.5', }}>{item.text}</Button>
              )
            })}
          </div>
        </div>
        <div className='sliderWrapper'>
          <DemoCarousel />
        </div>
      </div>
    </div>
  )
}

export default SavingSec
